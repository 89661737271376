<template>
  <div>
    <!-- <domain-view-info
      @refresh-data="refreshDomainInfoData"
      @refresh-service-data="refreshDomainServiceData"
    /> -->

    <b-tabs
      v-model="tabIndex"
      pills
      lazy
      vertical
      content-class="col-12 col-lg-10 mt-1 mt-md-0"
      nav-wrapper-class="col-lg-2 col-12"
      nav-class="nav-left"
    >
      <!-- vertical
        content-class="col-12 col-lg-10 mt-1 mt-md-0"
        nav-wrapper-class="col-lg-2 col-12"
      nav-class="nav-left" -->
      <template slot="default">
        <domain-view-info
          @refresh-data="refreshDomainInfoData"
          @refresh-service-data="refreshDomainServiceData"
        />
      </template>

      <!-- tab: 總覽 -->
      <b-tab active>
        <template #title>
          <b-img
            rounded
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-user.svg')"
          />
          <span class="font-weight-bold">總覽</span>
        </template>

        <domain-view-dashboard />
      </b-tab>

      <!-- tab: 服務紀錄 -->
      <b-tab active>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/service/ui-order.svg')"
          />
          <span class="font-weight-bold">服務紀錄</span>
        </template>

        <domain-view-service
          ref="domainViewService"
          @refresh-data="refreshDomainInfoData"
        />
      </b-tab>

      <!-- tab: 操作紀錄 -->
      <b-tab>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-history.svg')"
          />
          <span class="font-weight-bold">操作紀錄</span>
        </template>

        <domain-view-history />
      </b-tab>

      <!-- tab: 設定 -->
      <b-tab>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-settings.svg')"
          />
          <span class="font-weight-bold">設定</span>
        </template>

        <domain-view-dns />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
// API
import { ref, onUnmounted } from '@vue/composition-api'
// UI
import { BTabs, BTab, BImg } from 'bootstrap-vue'

import store from '@/store'
import DomainViewDashboard from './DomainViewDashboard.vue'
import DomainViewInfo from './DomainViewInfo.vue'
import DomainViewDns from './DomainViewDns.vue'
import DomainViewService from './DomainViewService.vue'
import DomainViewHistory from './DomainViewHistory.vue'
import { useDomainServiceView } from '../useDomain'
import useStoreModule from '../useStoreModule'
import router from '@/router'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BImg,
    DomainViewDashboard,
    DomainViewInfo,
    DomainViewDns,
    DomainViewHistory,
    DomainViewService,
  },
  data() {
    return {
      tabIndex: 0,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  setup() {
    // 註冊模組
    const DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME = 'admin-service-domain'

    if (!store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)
    })

    const domainUrl = router.currentRoute.params.id

    const {
      getDomainInfoData,
    } = useDomainServiceView()

    const domainViewService = ref(null)

    const refreshDomainInfoData = () => {
      store.commit('admin-service-domain/UPDATE_BUSY_LOADING_STATE', true)
      getDomainInfoData(domainUrl, () => {
        store.commit('admin-service-domain/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    const refreshDomainServiceData = () => {
      if (domainViewService.value) {
        domainViewService.value.refetchData()
      }
    }

    refreshDomainInfoData()

    return {
      domainUrl,
      domainViewService,
      refreshDomainInfoData,
      refreshDomainServiceData,
    }
  },
}
</script>
