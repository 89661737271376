<template>
  <div>
    <!-- 網域編輯 -->
    <domain-list-edit-modal
      ref="domainEditModal"
      @refetch-data="refetchServiceData"
    />

    <!-- 網域列表 -->
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              服務紀錄
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchData"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="searchAction"
              :options="domainActionOptions"
              :reduce="option => option.value"
              placeholder="類型"
              class="type-search-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDomainListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 類別 -->
        <template #cell(type)="data">
          <div class="table-col">
            <b-badge :variant="ui.actionType[1][data.item.action]">
              {{ ui.actionType[0][data.item.action] }}
            </b-badge>
          </div>
        </template>

        <!-- 欄位: 備註 -->
        <template #cell(content)="data">
          <div
            class="table-col item-description"
            @click="onSubmitServiceEdit(data.item)"
          >
            {{ data.item.content ? data.item.content : '---' }}
          </div>
        </template>

        <!-- 欄位: 約期 (年) -->
        <template #cell(quantity)="data">
          <div class="table-col">
            <span>{{ data.item.quantity }}</span>
          </div>
        </template>

        <!-- 欄位: 會員名稱 -->
        <template #cell(customer)="data">
          <div class="table-col">
            <b-media
              v-if="data.item.customer && data.item.customer_info"
              vertical-align="center"
            >
              <template #aside>
                <b-link :to="{ name: 'admin-customer-view', params: { id: data.item.customer_info.id } }">
                  <b-avatar
                    size="32"
                    :src="data.item.customer_info.image"
                    :text="avatarText(data.item.customer_info.name)"
                    variant="light-primary"
                  />
                </b-link>
              </template>
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text"
                  :to="{ name: 'admin-customer-view', params: { id: data.item.customer_info.id } }"
                >
                  {{ data.item.customer_info.name }} {{ data.item.customer_info.family_name }}
                </b-link>
              </div>
              <div
                class="text-muted item-description-email"
              >
                {{ data.item.customer_info.account }}
              </div>
            </b-media>

            <div
              v-else
              class="text-nowrap"
            >
              <b-avatar
                size="32"
                variant="light-primary"
              />
              <small class="text-muted ml-50">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 開始時間 -->
        <template #cell(startTime)="data">
          <div
            v-b-tooltip.hover.focus.v-secondary
            :title="data.item.start_time ? `${moment(data.item.start_time).format('HH:mm:ss')}` : null"
            variant="gradient-primary"
            class="table-title"
          >
            <span class="text-nowrap">
              {{ data.item.start_time ? moment(data.item.start_time).format("YYYY-MM-DD") : '---' }}
            </span>
          </div>
        </template>

        <!-- 欄位: 結束時間 -->
        <template #cell(endTime)="data">
          <div
            v-b-tooltip.hover.focus.v-secondary
            :title="data.item.end_time ? `${moment(data.item.end_time).format('HH:mm:ss')}` : null"
            variant="gradient-primary"
            class="table-title"
          >
            <span class="text-nowrap">
              {{ data.item.end_time ? moment(data.item.end_time).format("YYYY-MM-DD") : '---' }}
            </span>
          </div>
        </template>

        <!-- 欄位: 經銷商 / 網域商 -->
        <template #cell(dealer)="data">
          <div class="table-col">
            <div class="w-100">
              <span
                class="font-weight-bold d-block text-nowrap show-text"
              >
                {{ data.item.domain_name_dealer_info.name }}
              </span>
              <small class="text-muted">
                <div class="email-text">{{ data.item.domain_name_agent_info.agent }}</div>
              </small>
            </div>
          </div>
        </template>

        <!-- 欄位: 訂單 -->
        <template #cell(order)="data">
          <div class="table-col">
            <div
              v-if="data.item.system_order_id"
              class="d-block text-nowrap selection-group d-flex align-items-center"
            >
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                :to="{ name: 'admin-order-view', params: { id: data.item.order_info.id } }"
              >
                {{ data.item.system_order_id }}
              </b-link>
              <span
                v-b-tooltip.hover.focus.v-secondary
                title="前往訂單"
                class="selection-btn-icon-show"
              >
                <b-img
                  src="/dashboard/admin/images/table/external-link.svg"
                  height="14"
                  width="14"
                  class="cursor-pointer mb-25"
                />
              </span>
            </div>

            <div
              v-else
              class="text-nowrap"
            >
              <small class="text-muted ml-50">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 狀態 -->
        <template #cell(state)="data">
          <div class="table-col">
            <b-badge :variant="ui.stateType[1][data.item.state]">
              {{ ui.stateType[0][data.item.state] }}
            </b-badge>
            <!-- state: {{ data.item.state }} order: {{ data.item.order_info.state }} -->
          </div>
        </template>

        <!-- 欄位: 創建時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 更新時間 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <!-- <template #cell(actions)="data">
          <div
            class="d-flex mb-50"
          >
            <div
              class="actions-link-btn mr-25"
              @click="linkOrderView(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="前往訂單"
                src="/dashboard/admin/images/table/external-link.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template> -->

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex">
            <div
              v-if="data.item.system_order_id"
              class="actions-link-btn mr-25"
              @click="linkOrderView(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="前往訂單"
                src="/dashboard/admin/images/table/order.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitRemark(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="備註留言"
                src="/dashboard/admin/images/table/chat.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitServiceEdit(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="快速編輯"
                src="/dashboard/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <div class="mx-2 my-1">
        <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
      </div>
    </b-card>

    <remark-modal
      ref="domainActionRemarkExplorerModal"
      remark-explorer-id="domainActionRemarkExplorerModal"
      :key-array="keyArray"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="refetchData"
      @call-back-data="() => {}"
    >
      <template v-slot:header-info>
        <div class="mb-1" />
      </template>
    </remark-modal>
  </div>
</template>

<script>
// API
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, VBTooltip, BDropdown, BInputGroupAppend,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BMedia, BLink, BAvatar, BBadge,
  BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import DomainListEditModal from '../domain-list/DomainListEditModal.vue'

// Component
import { useDomainServiceInfoList, useDomainSetting } from '../useDomain'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    // BLink,
    // BCardHeader,
    // BCardBody,
    BFormInput,
    BTable,
    // BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,
    BInputGroupAppend,

    vSelect,
    TableTimeRange,
    DomainListEditModal,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tabIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      keyArray: [],
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark(item) {
      const resolveArray = []
      // resolveArray.push(`domain-${item.id}`)
      if (item.order_info && item.order_info.id) resolveArray.push(`order-${item.order_info.id}`)
      if (item.customer_info && item.customer_info.id) resolveArray.push(`customer-${item.customer_info.id}`)
      if (resolveArray.length <= 0) return
      this.keyArray = resolveArray
      setTimeout(() => { this.$refs.domainActionRemarkExplorerModal.getData() }, 200)
    },

    // (編輯)網域
    onSubmitServiceEdit(item) {
      setTimeout(() => { this.$refs.domainEditModal.getData(item) }, 200)
    },

    // (重新)整理
    refetchServiceData() {
      this.$emit('refresh-data')
      this.refetchData()
    },

    // (前往)訂單詳情
    linkOrderView(item) {
      this.$router.push({
        name: 'admin-order-view',
        params: {
          id: item.order_info.id,
        },
      })
    },
  },
  setup() {
    const {
      ui,
      domainActionOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDomainSetting()

    const {
      refDataListTable,
      totalNum,
      searchQuery,
      tableColumns,
      dataMeta,
      searchAction,
      timeRange,
      refetchData,

      getDomainListData,
      useAlertToast,
      useHttpCodeAlert,
    } = useDomainServiceInfoList()

    return {
      ui,
      domainActionOptions,
      avatarText,
      refonlineTime,
      onlineTime,
      updateOnline,
      refDataListTable,
      totalNum,
      searchQuery,
      tableColumns,
      dataMeta,
      searchAction,
      timeRange,
      refetchData,

      getDomainListData,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-description-email {
  // background-color: yellow;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

.type-search-select {
  width: 180px;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

@media (max-width: 768px) {
  .type-search-select {
    width: 100%;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
