<template>
  <div>
    <b-skeleton-wrapper :loading="adminServiceDomainState.isBusyLoading">
      <template #loading>
        <b-card no-body>
          <b-card-header>
            <h2 class="mb-0">
              {{ $router.currentRoute.params.id }} 的管理 DNS
            </h2>
          </b-card-header>

          <div class="p-2">
            <b-skeleton
              animation="wave"
              width="85%"
            />
            <b-skeleton
              animation="wave"
              width="55%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
          </div>
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <div v-if="!adminServiceDomainState.isBusyLoading">
      <b-card no-body>
        <!-- 標題 -->
        <b-card-header>
          <h2 class="mb-0">
            {{ adminServiceDomainState.domainInfo.url }} 的管理 DNS
          </h2>

          <div class="d-flex">
            <div
              v-if="!isServiceBusy && adminServiceDomainState.domainInfo.state === 2"
              class="actions-link-btn"
              @click="onSubmitActivate"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="手動開通"
                src="/dashboard/admin/images/table/product.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitRemark()"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="備註留言"
                src="/dashboard/admin/images/table/chat.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="重新整理"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="() => $emit('refresh-data')"
            >
              <b-img
                src="/dashboard/admin/images/table/refresh.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </b-card-header>

        <!-- 編輯/反向代理 -->
        <!-- <b-card-body class="pb-0">
          <div class="text-right">
            <b-button
              variant="outline-primary"
              class="ml-1 mt-md-2 mt-1"
              @click="editDomainClear"
            >
              清除快取
            </b-button>

            <b-button
              variant="outline-primary"
              class="ml-1 mt-md-2 mt-1"
              @click="editDomainContinue"
            >
              續約
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-1 mt-md-2 mt-1"
              @click="editServiceData"
            >
              編輯
            </b-button>
            <b-button
              v-b-modal.agent-modal
              variant="primary"
              class="ml-1 mt-md-2 mt-1"
            >
              反向代理
            </b-button>
          </div>
        </b-card-body> -->

        <!-- 網域基本訊息 -->
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              xl="5"
              lg="6"
            >
              <b-form-group
                label-cols="4"
                label="網域名稱"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.prefix }}{{ adminServiceDomainState.domainInfo.suffix }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="約期(年)"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.id ? adminServiceDomainState.domainInfo.quantityTotal : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="當前經銷商"
                class="m-0"
              >
                <div class="mt-50">
                  <span>{{ adminServiceDomainState.domainInfo.domain_name_agent_id !== null ? adminServiceDomainState.domainInfo.domain_name_agent_info.agent : '---' }}</span>
                  <span
                    v-if="adminServiceDomainState.domainInfo.domain_name_dealer_id"
                    v-b-tooltip.hover.focus.v-secondary
                    :title="adminServiceDomainState.domainInfo.domain_name_dealer_info.name"
                    class="ml-50"
                  >
                    <b-img
                      src="/dashboard/admin/images/common/notifyBlue.png"
                      height="22"
                      width="22"
                    />
                  </span>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="反向代理帳號"
                class="m-0"
              >
                <div class="mt-50">
                  <span>---</span>
                  <!-- <span
                    v-if="serviceData.agnetType"
                    v-b-tooltip.hover.focus.v-secondary
                    :title="serviceData.agnetType"
                    class="ml-50"
                  >
                    <b-img
                      src="/dashboard/admin/images/common/notifyBlue.png"
                      height="22"
                      width="22"
                    />
                  </span> -->
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="創建時間"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.created_at ? moment(adminServiceDomainState.domainInfo.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              xl="5"
              lg="6"
            >
              <b-form-group
                label-cols="4"
                label="狀態"
                class="m-0"
              >
                <div class="mt-50">
                  <b-badge
                    v-if="!isServiceBusy"
                    :variant="ui.stateType[1][adminServiceDomainState.domainInfo.state]"
                    class="cursor-pointer"
                  >
                    {{ ui.stateType[0][adminServiceDomainState.domainInfo.state] }}
                  </b-badge>

                  <b-badge
                    v-else
                    variant="light-secondary"
                  >
                    處理中
                  </b-badge>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="當前會員"
                class="m-0"
              >
                <!-- <div
                  v-if="adminServiceDomainState.domainInfo.customer"
                  class="mt-50"
                >
                  <b-link :to="{ name: 'admin-customer-view', params: { id: adminServiceDomainState.domainInfo.customer_info.id } }">
                    {{ adminServiceDomainState.domainInfo.customer_info.name }} {{ adminServiceDomainState.domainInfo.customer_info.family_name }}
                  </b-link>
                </div>

                <div v-else>
                  <span>---</span>
                </div> -->

                <div
                  v-if="adminServiceDomainState.domainInfo.customer"
                  class="d-block text-nowrap selection-group d-flex align-items-center mt-50"
                >
                  <b-link
                    class="font-weight-bold d-block text-nowrap show-text"
                    :to="{ name: 'admin-customer-view', params: { id: adminServiceDomainState.domainInfo.customer_info.id } }"
                  >
                    {{ adminServiceDomainState.domainInfo.customer_info.name }} {{ adminServiceDomainState.domainInfo.customer_info.family_name }}
                  </b-link>
                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    title="會員詳情"
                    class="selection-btn-icon-show"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/external-link.svg"
                      height="14"
                      width="14"
                      class="cursor-pointer mb-25"
                    />
                  </span>
                </div>

                <div
                  v-else
                  class="text-nowrap"
                >
                  <small class="text-muted ml-50">暫無資料</small>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="開始時間"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.id && adminServiceDomainState.domainInfo.start_time ? moment(adminServiceDomainState.domainInfo.start_time).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="到期時間"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.id && adminServiceDomainState.domainInfo.end_time ? moment(adminServiceDomainState.domainInfo.end_time).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="最後更新"
                class="m-0"
              >
                <div class="mt-50">
                  <div v-if="adminServiceDomainState.domainInfo.updated_at">
                    <span>
                      {{ updateOnline(adminServiceDomainState.domainInfo.updated_at, refonlineTime) === moment(adminServiceDomainState.domainInfo.updated_at).format("YYYY-MM-DD") ? moment(adminServiceDomainState.domainInfo.updated_at).format("YYYY-MM-DD HH:mm") : updateOnline(adminServiceDomainState.domainInfo.updated_at, refonlineTime) }}
                    </span>
                  </div>

                  <div v-else>
                    <span>---</span>
                  </div>
                </div>
              </b-form-group>

            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <remark-modal
      v-if="keyArray.length > 0"
      ref="domainInfoRemarkExplorerModal"
      remark-explorer-id="domainInfoRemarkExplorerModal"
      :key-array="keyArray"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="() => $emit('refresh-data')"
      @call-back-data="() => {}"
    >
      <template v-slot:header-info>
        <div class="mb-1" />
      </template>
    </remark-modal>

    <!-- {{ adminServiceDomainState }} -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, VBToggle, VBTooltip, BRow, BCol, BFormGroup, BImg, // BButton,
  BBadge, BLink, BSkeletonWrapper, BSkeleton, //   BAlert,
} from 'bootstrap-vue'
import moment from 'moment'
import router from '@/router'
import { useSwalToast } from '@/libs/mixins/index' // , useAlert
import { useDomainServiceView, useDomainSetting } from '../useDomain'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  components: {
    BImg,
    BCard,
    BLink,
    BBadge,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BSkeletonWrapper,
    BSkeleton,
    remarkModal,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      keyArray: [],
    }
  },
  computed: {
    adminServiceDomainState() {
      return this.$store.state['admin-service-domain']
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark() {
      const resolveArray = []
      resolveArray.push(`domain-${this.domainUrl}`)
      if (resolveArray.length <= 0) return
      this.keyArray = resolveArray
      setTimeout(() => { this.$refs.domainInfoRemarkExplorerModal.getData() }, 200)
    },

    // (觸發)服務執行
    onSubmitActivate() {
      const serviceInfo = this.adminServiceDomainState.domainInfo
      if (serviceInfo.state !== 2) return
      if (this.isServiceBusy) return
      const serviceDataInfo = `${this.ui.actionType[0][serviceInfo.action]} ${serviceInfo.prefix}${serviceInfo.suffix} / ${serviceInfo.quantity}年`

      this.useSwalAlertWarning('網域串接', `你確定要手動觸發 ${serviceDataInfo} 的串接服務嗎? ( 該動作包含網域購買流程及服務新增，請先確認網域商餘額，以免購買失敗 )`)
        .then(result => {
          if (result.value) {
            this.isServiceBusy = true
            // this.$emit('refresh-service-data')
            this.setOrderServiceDomain({
              domain_name_id: serviceInfo.errorId,
            }).then(() => {
              this.$emit('refresh-data')
              this.$emit('refresh-service-data')
              this.isServiceBusy = false
              // this.checkDomainStatusRepeatedly()
            })
              .catch(error => {
                this.isServiceBusy = false
                this.useSwalAlertCenter(false, '觸發失敗', error.response.data.message)
              })
          }
        })
    },

    async checkDomainStatusRepeatedly(attempt = 1) {
      if (attempt > 10) {
        this.$emit('refresh-data')
        this.$emit('refresh-service-data')
        this.useSwalAlertCenter(true, '串接失敗', '服務啟用失敗，請稍後再嘗試')
        this.isServiceBusy = false
        return
      }

      const serviceData = await new Promise(resolve => {
        this.checkDomainInfoState(resolve)
      })

      if (!serviceData || serviceData.state !== 0) {
        this.$emit('refresh-data')
        this.$emit('refresh-service-data')
        this.isServiceBusy = false
        return
      }

      await new Promise(resolve => setTimeout(resolve, 3000))

      this.checkDomainStatusRepeatedly(attempt + 1)
    },

  },
  setup() {
    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDomainSetting()

    const {
      isServiceBusy,
      setOrderServiceDomain,
      checkDomainInfoState,
    } = useDomainServiceView()

    const domainUrl = router.currentRoute.params.id

    return {
      ui,
      domainUrl,
      refonlineTime,
      onlineTime,
      updateOnline,
      isServiceBusy,
      setOrderServiceDomain,
      checkDomainInfoState,
    }
  },
}
</script>

<style lang="scss" scoped>
.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}
</style>
